import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Autocomplete,
  Select, // Added Select component
  MenuItem, // Added MenuItem component
  FormControl, // Added FormControl component
  InputLabel, // Added InputLabel component
} from "@mui/material";
import axios from "axios";
import { baseURL } from "../support/baseURL";
import { insertedBy } from "../support/sessionUserName";

const AddOldBalanceModal = ({
  openModal,
  handleCloseModal,
  fetchedData,
  customerOptions = [],
}) => {
  const [newOldBalance, setNewOldBalance] = useState({
    customer_id: "",
    inserted_by: insertedBy(), // Replace with dynamic user info if needed
    ob: [{ ob: "", ob_amount: "" }],
    maaral: [{ maaral: "", maaral_amount: "" }],
  });

  // Functions to dynamically add/remove OB and Maaral fields
  const handleAddOBField = () => {
    setNewOldBalance((prev) => ({
      ...prev,
      ob: [...prev.ob, { ob: "", ob_amount: "" }],
    }));
  };

  const handleRemoveOBField = (index) => {
    setNewOldBalance((prev) => ({
      ...prev,
      ob: prev.ob.filter((_, i) => i !== index),
    }));
  };

  const handleAddMaaralField = () => {
    setNewOldBalance((prev) => ({
      ...prev,
      maaral: [...prev.maaral, { maaral: "", maaral_amount: "" }],
    }));
  };

  const handleRemoveMaaralField = (index) => {
    setNewOldBalance((prev) => ({
      ...prev,
      maaral: prev.maaral.filter((_, i) => i !== index),
    }));
  };

  // Functions to update OB and Maaral values
  const handleOBChange = (index, field, value) => {
    const updatedOBs = [...newOldBalance.ob];
    updatedOBs[index][field] = value;
    setNewOldBalance({ ...newOldBalance, ob: updatedOBs });
  };

  const handleMaaralChange = (index, field, value) => {
    const updatedMaarals = [...newOldBalance.maaral];
    updatedMaarals[index][field] = value;
    setNewOldBalance({ ...newOldBalance, maaral: updatedMaarals });
  };

  // Handle form submission
  const handleAddOldBalance = async () => {
    try {
      console.log("add old balance", newOldBalance);
      const inputData = {
        ...newOldBalance,
        ob: newOldBalance.ob[0].ob,
        ob_amount: newOldBalance.ob[0].ob_amount,
        maaral_json: JSON.stringify(newOldBalance.maaral),
      };

      delete inputData.maaral;

      console.log("input data", inputData);
      const response = await axios.post(`${baseURL}/ob/addOB`, inputData);
      console.log("response of ob", response);
      if (response.data.mStatus === 400) {
        alert(response.data.mData.sqlMessage);
      } else if (response.data.mStatus === 200) {
        alert("OB Data Added Successfully");
        fetchedData();
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error adding OB data:", error);
    }
  };

  return (
    <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="md">
      <DialogTitle>Create Old Balance</DialogTitle>
      <DialogContent>
        {/* Customer Selection */}
        <Autocomplete
          options={customerOptions}
          getOptionLabel={(option) => option.contact_person_name}
          value={
            customerOptions.find(
              (option) => option.customer_id === newOldBalance.customer_id
            ) || null
          }
          onChange={(event, newValue) => {
            setNewOldBalance({
              ...newOldBalance,
              customer_id: newValue ? newValue.customer_id : "",
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Customer"
              variant="outlined"
              fullWidth
            />
          )}
          sx={{ mb: 3 }}
        />

        {/* Old Balances Section */}
        <h3>Old Balances</h3>
        {newOldBalance.ob.map((ob, index) => (
          <Grid container spacing={2} key={index} alignItems="center">
            <Grid item xs={5}>
              {/* <TextField
                fullWidth
                label="OB"
                value={ob.ob}
                onChange={(e) => handleOBChange(index, "ob", e.target.value)}
              /> */}
              <FormControl fullWidth margin="dense">
                <InputLabel>Old Balance</InputLabel>
                <Select
                  name="ob"
                  value={ob.ob}
                  onChange={(e) => handleOBChange(index, "ob", e.target.value)}
                  required
                >
                  <MenuItem value="70">70</MenuItem>
                  <MenuItem value="80">80</MenuItem>
                  <MenuItem value="WT">WT</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="OB Amount"
                type="number"
                value={ob.ob_amount}
                onChange={(e) =>
                  handleOBChange(index, "ob_amount", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={2}>
              {index > 0 && (
                <Button
                  color="error"
                  onClick={() => handleRemoveOBField(index)}
                >
                  delete
                </Button>
              )}
            </Grid>
          </Grid>
        ))}
        {/* <Button onClick={handleAddOBField} sx={{ mt: 2 }}>
          Add More OB
        </Button> */}

        {/* Maaral Section */}
        <h3>Maarals</h3>
        {newOldBalance.maaral.map((maaral, index) => (
          <Grid container spacing={2} key={index} alignItems="center">
            <Grid item xs={5}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Maaral</InputLabel>
                <Select
                  name="maaral"
                  value={maaral.maaral}
                  onChange={(e) =>
                    handleMaaralChange(index, "maaral", e.target.value)
                  }
                  required
                >
                  <MenuItem value="70">70</MenuItem>
                  <MenuItem value="80">80</MenuItem>
                  <MenuItem value="90">90</MenuItem>
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="Maaral Amount"
                type="number"
                value={maaral.maaral_amount}
                onChange={(e) =>
                  handleMaaralChange(index, "maaral_amount", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={2}>
              {index > 0 && (
                <Button
                  color="error"
                  onClick={() => handleRemoveMaaralField(index)}
                >
                  delete
                </Button>
              )}
            </Grid>
          </Grid>
        ))}
        <Button onClick={handleAddMaaralField} sx={{ mt: 2 }}>
          Add More Maaral
        </Button>
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions>
        <Button onClick={handleCloseModal} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleAddOldBalance}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOldBalanceModal;
