// /* eslint-disable no-unused-vars */
// import * as React from "react";
// import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   Select, // Added Select component
//   MenuItem, // Added MenuItem component
//   FormControl, // Added FormControl component
//   InputLabel, // Added InputLabel component
//   Grid,
//   Autocomplete,
// } from "@mui/material";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
// import BorderColorIcon from "@mui/icons-material/BorderColor";
// import DeleteIcon from "@mui/icons-material/Delete";
// import "../../index.css";
// import * as XLSX from "xlsx";
// import { handleDeleteOB, handleDeleteUser } from "../support/deleteData";
// import { insertedBy } from "../support/sessionUserName";
// import { baseURL } from "../support/baseURL";
// import { fetchCustomerOptionData } from "../support/customerData";
// import AddOldBalanceModal from "./CreateOBModal";

// export default function OldBalance() {
//   const [rows, setRows] = useState([]);
//   const [openModal, setOpenModal] = useState(false);
//   const [editOpen, setEditOpen] = useState(false);
//   const [editedRow, setEditedRow] = useState(null);
//   const [editedID, setEditedID] = useState("");
//   const [editedCustomerID, setEditedCustomerID] = useState("");
//   const [editOB, setEditedOB] = useState("");
//   const [editOBAmount, setEditedOBAmount] = useState("");
//   const [editedMaaral, setEditedMaaral] = useState("");
//   const [editedMaaralAmount, setEditedMaaralAmount] = useState("");
//   const [customerOptions, setCustomerOptions] = useState([]);

//   const [newOldBalance, setNewOldBalance] = useState({
//     ob: "",
//     ob_amount: "",
//     maaral: "",
//     maaral_amount: "",
//     customer_id: "",
//     inserted_by: insertedBy(),
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setNewOldBalance({ ...newOldBalance, [name]: value });
//   };

//   const handleCloseModal = () => {
//     setOpenModal(false);
//     setNewOldBalance({
//       ob: "",
//       maaral: "",
//       maaral_amount: "",
//       ob_amount: "",
//       customer_id: "",
//       inserted_by: insertedBy(),
//     });
//   };

//   const handleOpenModal = () => {
//     setOpenModal(true);
//   };

//   const handleEditOpen = (row) => {
//     setEditedRow(row);
//     setEditedID(row.id);
//     setEditedCustomerID(row.customer_id);
//     setEditedOB(row.ob);
//     setEditedOBAmount(row.ob_amount);
//     setEditedMaaral(row.maaral);
//     setEditedMaaralAmount(row.maaral_amount);
//     setEditOpen(true);
//   };

//   const handleEditClose = () => {
//     setEditOpen(false);
//   };
//   const handleAddOldBalance = async () => {
//     try {
//       // Perform API call to add user
//       const addResponse = await axios.post(
//         `${baseURL}/ob/addOB`,
//         newOldBalance
//       );
//       if (addResponse.data.mStatus === 400) {
//         alert(addResponse.data.mData.sqlMessage);
//       } else if (addResponse.data.mStatus === 200) {
//         alert("Users Data Added Successfully");
//         fetchedData();
//         handleCloseModal();
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const getRowClassName = (params) => {
//     return "customRow"; // Return the class name for row styling
//   };

//   useEffect(() => {
//     fetchedData();
//   }, []);

//   useEffect(() => {
//     fetchCustomerOptionData("skvOrders", setCustomerOptions); // Fetch customer data on component mount
//   }, [setCustomerOptions]);

//   const fetchedData = async () => {
//     try {
//       const body = {
//         customer_id: "All",
//       };
//       const response = await axios.post(`${baseURL}/ob/getOB`, body);

//       console.log("ob response:", response);

//       if (response.data.mStatus === 200) {
//         setRows(response.data.mData);
//       } else {
//         alert("error while retrieving ob data");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleEditSubmit = async () => {
//     try {
//       // Construct the updated order object including order_id
//       const updatedOB = {
//         id: editedRow.id,
//         customer_id: editedRow.customer_id,
//         ob: editedRow.ob,
//         maaral: editedRow.maaral,
//         ob_amount: editedRow.ob_amount,
//         maaral_amount: editedRow.maaral_amount,
//         updated_by: insertedBy(),
//       };

//       // Update the row with the edited data
//       const updatedRows = rows.map((row) => {
//         if (row.id === editedID) {
//           return {
//             ...row,
//             customer_id: editedCustomerID,
//             ob: editOB,
//             maaral: editedMaaral,
//             ob_amount: editOBAmount,
//             maaral_amount: editedMaaralAmount,
//             id: editedID,
//           };
//         }
//         return row;
//       });
//       setRows(updatedRows);

//       // Send updated data to API
//       const response = await axios.put(`${baseURL}/ob/updateOB`, updatedOB);

//       // Handle success or failure
//       if (response.data.mStatus === 200) {
//         alert("OB Data updated successfully");
//         fetchedData();
//       } else {
//         alert("Failed to update OB");
//       }

//       console.log("onb response", response);

//       handleEditClose();
//     } catch (error) {
//       console.error("Error updating OB:", error);
//     }
//   };

//   const columns = [
//     {
//       field: "id",
//       headerName: "OB ID",
//       width: 120,
//       headerClassName: "customHeader",
//       cellClassName: "customCell",
//     },
//     {
//       field: "contact_person_name",
//       headerName: "Customer Name",
//       width: 200,
//       headerClassName: "customHeader",
//       cellClassName: "customCell",
//     },
//     {
//       field: "ob",
//       headerName: "Old Balance",
//       width: 150,
//       headerClassName: "customHeader",
//       cellClassName: "customCell",
//     },
//     {
//       field: "ob_amount",
//       headerName: "Old Balance Amount",
//       width: 150,
//       headerClassName: "customHeader",
//       cellClassName: "customCell",
//     },
//     {
//       field: "maaral",
//       headerName: "Maaral",
//       width: 150,
//       headerClassName: "customHeader",
//       cellClassName: "customCell",
//     },
//     {
//       field: "maaral_amount",
//       headerName: "Maaral Amount",
//       width: 150,
//       headerClassName: "customHeader",
//       cellClassName: "customCell",
//     },
//     {
//       field: "maaral_json",
//       headerName: "Maaral JSON",
//       width: 150,
//       headerClassName: "customHeader",
//       cellClassName: "customCell",
//     },
//     {
//       field: "Action",
//       headerName: "Action",
//       width: 200,
//       headerClassName: "customHeader",
//       cellClassName: "customCell",
//       renderCell: (params) => {
//         return (
//           <div>
//             {/* Replace these with your desired icons */}
//             <BorderColorIcon
//               style={{ cursor: "pointer", marginRight: "5px" }}
//               onClick={() => handleEditOpen(params.row)}
//             />
//             <DeleteIcon
//               style={{ cursor: "pointer", marginRight: "5px" }}
//               onClick={() => handleDeleteOB(params.row.id, fetchedData)}
//             />
//           </div>
//         );
//       },
//     },
//   ];

//   const handleExportToExcel = () => {
//     const worksheet = XLSX.utils.json_to_sheet(rows);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "users");
//     XLSX.writeFile(workbook, "users.xlsx");
//   };

//   return (
//     <Box sx={{ height: 500, width: "97%", marginX: "2%" }}>
//       <Grid
//         container
//         spacing={2}
//         alignItems="center"
//         justifyContent="space-between"
//       >
//         {/* Add User Button */}
//         <Grid item xs={12} sm={6} md={3}>
//           <Button
//             onClick={handleOpenModal}
//             startIcon={<PeopleAltTwoToneIcon />}
//             variant="contained"
//             fullWidth
//             sx={{ paddingY: 1.5 }}
//           >
//             Add Old Balance
//           </Button>
//         </Grid>

//         {/* Export to Excel Button */}
//         <Grid item xs={12} sm={6} md={3}>
//           <Button
//             variant="contained"
//             color="secondary"
//             fullWidth
//             startIcon={<DownloadOutlinedIcon />}
//             onClick={handleExportToExcel}
//             sx={{ paddingY: 1.5 }}
//           >
//             Export to Excel
//           </Button>
//         </Grid>
//       </Grid>

//       {openModal && (
//         <AddOldBalanceModal
//           openModal={openModal}
//           handleCloseModal={handleCloseModal}
//           fetchedData={fetchedData}
//           customerOptions={customerOptions}
//         />
//       )}

//       {/* Edit popup component */}
//       <Dialog open={editOpen} onClose={handleEditClose}>
//         <PeopleAltTwoToneIcon sx={{ mr: "20px" }} />
//         <DialogTitle>Edit Old Balance</DialogTitle>
//         <DialogContent sx={{ width: "500px" }}>
//           <br />
//           {/* Customer Selection */}
//           <Autocomplete
//             options={customerOptions}
//             getOptionLabel={(option) => option.contact_person_name}
//             value={
//               customerOptions.find(
//                 (option) => option.customer_id === editedRow?.customer_id
//               ) || null
//             }
//             onChange={(event, newValue) =>
//               setEditedRow({
//                 ...editedRow,
//                 customer_id: newValue?.customer_id || "",
//               })
//             }
//             renderInput={(params) => (
//               <TextField {...params} label="Select Customer" fullWidth />
//             )}
//           />
//           <br />

//           {/* Old Balance */}

//           <Grid container spacing={2}>
//             <Grid item xs={6}>
//               <FormControl fullWidth margin="dense">
//                 <InputLabel>Old Balance</InputLabel>
//                 <Select
//                   name="ob"
//                   value={editedRow?.ob || ""}
//                   onChange={(e) =>
//                     setEditedRow({ ...editedRow, ob: e.target.value })
//                   }
//                   required
//                 >
//                   <MenuItem value="70">70</MenuItem>
//                   <MenuItem value="80">80</MenuItem>
//                   <MenuItem value="WT">WT</MenuItem>
//                 </Select>
//               </FormControl>
//             </Grid>

//             <Grid item xs={6}>
//               <TextField
//                 name="ob_amount"
//                 label="OB Amount"
//                 variant="outlined"
//                 fullWidth
//                 margin="dense"
//                 value={editedRow?.ob_amount || ""}
//                 onChange={(e) =>
//                   setEditedRow({ ...editedRow, ob_amount: e.target.value })
//                 }
//               />
//             </Grid>
//           </Grid>

//           <Grid container spacing={2}>
//             <Grid item xs={6}>
//               <FormControl fullWidth margin="dense">
//                 <InputLabel>Maaral</InputLabel>
//                 <Select
//                   name="maaral"
//                   value={editedRow?.maaral || ""}
//                   onChange={(e) =>
//                     setEditedRow({ ...editedRow, maaral: e.target.value })
//                   }
//                   required
//                 >
//                   <MenuItem value="70">70</MenuItem>
//                   <MenuItem value="80">80</MenuItem>
//                   <MenuItem value="90">90</MenuItem>
//                   <MenuItem value="Cash">Cash</MenuItem>
//                   <MenuItem value="Others">Others</MenuItem>
//                 </Select>
//               </FormControl>
//             </Grid>

//             <Grid item xs={6}>
//               <TextField
//                 name="maaral_amount"
//                 label="Maaral Amount"
//                 variant="outlined"
//                 fullWidth
//                 margin="dense"
//                 value={editedRow?.maaral_amount || ""}
//                 onChange={(e) =>
//                   setEditedRow({ ...editedRow, maaral_amount: e.target.value })
//                 }
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>

//         <DialogActions>
//           <Button onClick={handleEditClose} color="error">
//             Cancel
//           </Button>
//           <Button
//             onClick={handleEditSubmit}
//             color="primary"
//             variant="contained"
//           >
//             Save Changes
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <DataGrid
//         //rows={rows}
//         rows={rows.map((row, index) => ({ ...row, slno: index + 1 }))}
//         columns={columns}
//         getRowClassName={getRowClassName} // Apply row styling
//         initialState={{
//           pagination: {
//             paginationModel: {
//               pageSize: 5,
//             },
//           },
//         }}
//         pageSizeOptions={[5]}
//         getRowId={(row) => row.id}
//       />
//       {/* Display user details */}
//     </Box>
//   );
// }

/* eslint-disable no-unused-vars */
// import Box from "@mui/material/Box";

/* eslint-disable no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Autocomplete,
} from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "../../index.css";
import * as XLSX from "xlsx";
import { handleDeleteOB, handleDeleteUser } from "../support/deleteData";
import { insertedBy } from "../support/sessionUserName";
import { baseURL } from "../support/baseURL";
import { fetchCustomerOptionData } from "../support/customerData";
import AddOldBalanceModal from "./CreateOBModal";

export default function OldBalance() {
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [jsonModalOpen, setJsonModalOpen] = useState(false);
  const [selectedJson, setSelectedJson] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editedRow, setEditedRow] = useState(null);
  const [editedID, setEditedID] = useState("");
  const [editedCustomerID, setEditedCustomerID] = useState("");
  const [editOB, setEditedOB] = useState("");
  const [editOBAmount, setEditedOBAmount] = useState("");
  const [editedMaaral, setEditedMaaral] = useState("");
  const [editedMaaralAmount, setEditedMaaralAmount] = useState("");
  const [editedMaaralJSON, setEditedMaaralJSON] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);

  const [newOldBalance, setNewOldBalance] = useState({
    ob: "",
    ob_amount: "",
    maaral: "",
    maaral_amount: "",
    customer_id: "",
    inserted_by: insertedBy(),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewOldBalance({ ...newOldBalance, [name]: value });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setNewOldBalance({
      ob: "",
      maaral: "",
      maaral_amount: "",
      ob_amount: "",
      customer_id: "",
      inserted_by: insertedBy(),
    });
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleEditOpen = (row) => {
    setEditedRow(row);
    setEditedID(row.id);
    setEditedCustomerID(row.customer_id);
    setEditedOB(row.ob);
    setEditedOBAmount(row.ob_amount);
    setEditedMaaral(row.maaral);
    setEditedMaaralAmount(row.maaral_amount);
    setEditedMaaralJSON(row.maaral_json);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleJsonModalOpen = (row) => {
    try {
      const parsedJson = JSON.parse(row.maaral_json || "[]");
      // Add an index-based ID if no ID exists in the JSON objects
      const deepCopiedJson = parsedJson.map((item, index) => ({
        ...item,
        tempId: index, // Temporary ID based on array index
      }));
      setSelectedJson(deepCopiedJson);
      setSelectedRow(row);
      setJsonModalOpen(true);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      alert("Invalid JSON data");
    }
  };

  const handleJsonModalClose = () => {
    setJsonModalOpen(false);
    setSelectedJson(null);
    setSelectedRow(null);
  };

  const handleDeleteJsonItem = async (tempId) => {
    const updatedJson = selectedJson
      .filter((item) => item.tempId !== tempId)
      .map((item, index) => ({ ...item, tempId: index })); // Reassign tempIds after deletion
    setSelectedJson(updatedJson);

    const updatedRow = {
      ...selectedRow,
      maaral_json: JSON.stringify(
        updatedJson.map(({ tempId, ...rest }) => rest)
      ), // Remove tempId before saving
      updated_by: insertedBy(),
    };
    await updateJsonInBackend(updatedRow);
  };

  const handleEditJsonItem = (tempId, key, value) => {
    const updatedJson = selectedJson.map((item) =>
      item.tempId === tempId ? { ...item, [key]: value } : { ...item }
    );
    setSelectedJson(updatedJson);
  };

  const handleSaveJsonChanges = async () => {
    const updatedRow = {
      ...selectedRow,
      maaral_json: JSON.stringify(
        selectedJson.map(({ tempId, ...rest }) => rest)
      ), // Remove tempId before saving
      updated_by: insertedBy(),
    };
    console.log("updatedRow", updatedRow);
    await updateJsonInBackend(updatedRow);
    handleJsonModalClose();
  };

  const updateJsonInBackend = async (updatedRow) => {
    try {
      const response = await axios.put(`${baseURL}/ob/updateOB`, updatedRow);
      if (response.data.mStatus === 200) {
        alert("JSON Data updated successfully");
        fetchedData();
      } else {
        alert("Failed to update JSON data");
      }
    } catch (error) {
      console.error("Error updating JSON:", error);
    }
  };

  const handleAddOldBalance = async () => {
    try {
      const addResponse = await axios.post(
        `${baseURL}/ob/addOB`,
        newOldBalance
      );
      if (addResponse.data.mStatus === 400) {
        alert(addResponse.data.mData.sqlMessage);
      } else if (addResponse.data.mStatus === 200) {
        alert("Users Data Added Successfully");
        fetchedData();
        handleCloseModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRowClassName = (params) => {
    return "customRow";
  };

  useEffect(() => {
    fetchedData();
  }, []);

  useEffect(() => {
    fetchCustomerOptionData("skvOrders", setCustomerOptions);
  }, [setCustomerOptions]);

  const fetchedData = async () => {
    try {
      const body = { customer_id: "All" };
      const response = await axios.post(`${baseURL}/ob/getOB`, body);
      if (response.data.mStatus === 200) {
        setRows(response.data.mData);
      } else {
        alert("error while retrieving ob data");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditSubmit = async () => {
    try {
      const updatedOB = {
        id: editedRow.id,
        customer_id: editedRow.customer_id,
        ob: editedRow.ob,
        maaral: editedRow.maaral,
        ob_amount: editedRow.ob_amount,
        maaral_amount: editedRow.maaral_amount,
        maaral_json: editedRow.maaral_json,
        updated_by: insertedBy(),
      };

      const updatedRows = rows.map((row) =>
        row.id === editedID
          ? {
              ...row,
              customer_id: editedCustomerID,
              ob: editOB,
              maaral: editedMaaral,
              ob_amount: editOBAmount,
              maaral_amount: editedMaaralAmount,
              maaral_json: editedMaaralJSON,
              id: editedID,
            }
          : row
      );
      setRows(updatedRows);

      const response = await axios.put(`${baseURL}/ob/updateOB`, updatedOB);
      if (response.data.mStatus === 200) {
        alert("OB Data updated successfully");
        fetchedData();
      } else {
        alert("Failed to update OB");
      }
      handleEditClose();
    } catch (error) {
      console.error("Error updating OB:", error);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "OB ID",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "contact_person_name",
      headerName: "Customer Name",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "ob",
      headerName: "Old Balance",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "ob_amount",
      headerName: "Old Balance Amount",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    // {
    //   field: "maaral",
    //   headerName: "Maaral",
    //   width: 150,
    //   headerClassName: "customHeader",
    //   cellClassName: "customCell",
    // },
    // {
    //   field: "maaral_amount",
    //   headerName: "Maaral Amount",
    //   width: 150,
    //   headerClassName: "customHeader",
    //   cellClassName: "customCell",
    // },
    {
      field: "maaral_json",
      headerName: "Maaral Details",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => (
        <div>
          {params.value ? (
            <VisibilityIcon
              style={{ cursor: "pointer" }}
              onClick={() => handleJsonModalOpen(params.row)}
            />
          ) : (
            "N/A"
          )}
        </div>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => (
        <div>
          <BorderColorIcon
            style={{ cursor: "pointer", marginRight: "5px" }}
            onClick={() => handleEditOpen(params.row)}
          />
          <DeleteIcon
            style={{ cursor: "pointer", marginRight: "5px" }}
            onClick={() => handleDeleteOB(params.row.id, fetchedData)}
          />
        </div>
      ),
    },
  ];

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "users");
    XLSX.writeFile(workbook, "users.xlsx");
  };

  return (
    <Box sx={{ height: 500, width: "97%", marginX: "2%" }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={6} md={3}>
          <Button
            onClick={handleOpenModal}
            startIcon={<PeopleAltTwoToneIcon />}
            variant="contained"
            fullWidth
            sx={{ paddingY: 1.5 }}
          >
            Add Old Balance
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            startIcon={<DownloadOutlinedIcon />}
            onClick={handleExportToExcel}
            sx={{ paddingY: 1.5 }}
          >
            Export to Excel
          </Button>
        </Grid>
      </Grid>

      {openModal && (
        <AddOldBalanceModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          fetchedData={fetchedData}
          customerOptions={customerOptions}
        />
      )}

      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Old Balance</DialogTitle>
        <DialogContent sx={{ width: "500px" }}>
          <br />
          <Autocomplete
            options={customerOptions}
            getOptionLabel={(option) => option.contact_person_name}
            value={
              customerOptions.find(
                (option) => option.customer_id === editedRow?.customer_id
              ) || null
            }
            onChange={(event, newValue) =>
              setEditedRow({
                ...editedRow,
                customer_id: newValue?.customer_id || "",
              })
            }
            renderInput={(params) => (
              <TextField {...params} label="Select Customer" fullWidth />
            )}
          />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Old Balance</InputLabel>
                <Select
                  name="ob"
                  value={editedRow?.ob || ""}
                  onChange={(e) =>
                    setEditedRow({ ...editedRow, ob: e.target.value })
                  }
                  required
                >
                  <MenuItem value="70">70</MenuItem>
                  <MenuItem value="80">80</MenuItem>
                  <MenuItem value="WT">WT</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="ob_amount"
                label="OB Amount"
                variant="outlined"
                fullWidth
                margin="dense"
                value={editedRow?.ob_amount || ""}
                onChange={(e) =>
                  setEditedRow({ ...editedRow, ob_amount: e.target.value })
                }
              />
            </Grid>
          </Grid>
          {/* <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Maaral</InputLabel>
                <Select
                  name="maaral"
                  value={editedRow?.maaral || ""}
                  onChange={(e) =>
                    setEditedRow({ ...editedRow, maaral: e.target.value })
                  }
                  required
                >
                  <MenuItem value="70">70</MenuItem>
                  <MenuItem value="80">80</MenuItem>
                  <MenuItem value="90">90</MenuItem>
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="maaral_amount"
                label="Maaral Amount"
                variant="outlined"
                fullWidth
                margin="dense"
                value={editedRow?.maaral_amount || ""}
                onChange={(e) =>
                  setEditedRow({ ...editedRow, maaral_amount: e.target.value })
                }
              />
            </Grid>
          </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="error">
            Cancel
          </Button>
          <Button
            onClick={handleEditSubmit}
            color="primary"
            variant="contained"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={jsonModalOpen}
        onClose={handleJsonModalClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Maaral JSON Details</DialogTitle>
        <DialogContent>
          {selectedJson && selectedJson.length > 0 ? (
            <Grid container spacing={2}>
              {selectedJson.map((item) => (
                <Grid item xs={12} key={item.tempId}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                      <TextField
                        label="Maaral"
                        value={item.maaral}
                        fullWidth
                        onChange={(e) =>
                          handleEditJsonItem(
                            item.tempId,
                            "maaral",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Maaral Amount"
                        value={item.maaral_amount}
                        fullWidth
                        onChange={(e) =>
                          handleEditJsonItem(
                            item.tempId,
                            "maaral_amount",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        color="error"
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteJsonItem(item.tempId)}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ) : (
            <p>No JSON data available</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleJsonModalClose} color="error">
            Close
          </Button>
          <Button
            onClick={handleSaveJsonChanges}
            color="primary"
            variant="contained"
          >
            Save Maaral Changes
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={jsonModalOpen}
        onClose={handleJsonModalClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Maaral Details</DialogTitle>
        <br></br>
        <DialogContent>
          {selectedJson && selectedJson.length > 0 ? (
            <Grid container spacing={2}>
              {selectedJson.map((item) => (
                <Grid item xs={12} key={item.tempId}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel>Maaral</InputLabel>
                        <Select
                          value={item.maaral || ""}
                          onChange={(e) =>
                            handleEditJsonItem(
                              item.tempId,
                              "maaral",
                              e.target.value
                            )
                          }
                          label="Maaral"
                        >
                          <MenuItem value="70">70</MenuItem>
                          <MenuItem value="80">80</MenuItem>
                          <MenuItem value="90">90</MenuItem>
                          <MenuItem value="Cash">Cash</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Maaral Amount"
                        value={item.maaral_amount}
                        fullWidth
                        onChange={(e) =>
                          handleEditJsonItem(
                            item.tempId,
                            "maaral_amount",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        color="error"
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteJsonItem(item.tempId)}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ) : (
            <p>No JSON data available</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleJsonModalClose} color="error">
            Close
          </Button>
          <Button
            onClick={handleSaveJsonChanges}
            color="primary"
            variant="contained"
          >
            Save Maaral Changes
          </Button>
        </DialogActions>
      </Dialog>

      <DataGrid
        rows={rows.map((row, index) => ({ ...row, slno: index + 1 }))}
        columns={columns}
        getRowClassName={getRowClassName}
        initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
        pageSizeOptions={[5]}
        getRowId={(row) => row.id}
      />
    </Box>
  );
}
