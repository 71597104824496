/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
} from "@mui/material";
import axios from "axios";
import { currentDate, generateSummary } from "../support/receiptGeneration";
import { baseURL } from "../support/baseURL";
import { selectTheCustomerAlert } from "../support/sweetAlert";
import { generateOverallCustomerOB } from "./generateOverAllOB";

export default function CustomerTable({ ob }) {
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerOrderedData, setCustomerOrderedData] = useState([]);
  const [newCustomer, setNewCustomer] = useState({ customer_id: "" });
  const [orderStatusFilter, setOrderStatusFilter] = useState("all"); // New state for order status filter
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [purityFilter, setPurityFilter] = useState("");

  // Create a function to handle purity filter change
  const handlePurityFilterChange = (e) => {
    setPurityFilter(e.target.value);
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setNewCustomer({ ...newCustomer, [name]: value });

    try {
      const response = await axios.post(
        `${baseURL}/customers/getCustomerMappedOrders`,
        {
          customer_id: value,
        }
      );
      if (response.data.mStatus === 200) {
        const orderedDataWithIds = response.data.mData.map((row, index) => ({
          ...row,
          id: index + 1, // Generate unique ID for each row
        }));
        const nonDeletedData = orderedDataWithIds.filter(
          (items) => items.is_delete == 0
        );
        setCustomerOrderedData(nonDeletedData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrderStatusFilterChange = (e) => {
    setOrderStatusFilter(e.target.value);
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const fetchCustomerData = async () => {
    try {
      const response = await axios.get(`${baseURL}/customers/getCustomer`);
      if (response.data.mData) {
        const activeCustomers = response.data.mData.filter(
          (data) => data.is_delete !== 1
        );
        const skvCustomers = activeCustomers.filter(
          (data) => data.purity === "SKV/5" || data.purity === "SKV?80"
        );
        setCustomerOptions(skvCustomers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  const filteredCustomerOrderedData = customerOrderedData.filter((item) => {
    if (orderStatusFilter !== "all") {
      if (item.order_status.toString() !== orderStatusFilter) {
        return false;
      }
    }

    if (purityFilter && item.purity !== purityFilter) {
      return false;
    }

    // Filter by date range
    if (fromDate && toDate) {
      const OD = item.order_date === null ? "0000-00-00" : item.order_date;
      const orderDate = new Date(OD.split("T")[0]);
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);

      if (orderDate < fromDateObj || orderDate > toDateObj) {
        return false;
      }
    }

    return true;
  });

  const selectedCustomerName =
    customerOptions.find(
      (customer) => customer.customer_id === newCustomer.customer_id
    )?.contact_person_name || "";

  const getOBCustomerDetails = async (customer_id) => {
    try {
      return await axios.post(`${baseURL}/ob/getOB`, {
        customer_id: parseInt(customer_id),
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const generateReceipt = async () => {
    // Check if a customer is selected
    if (!newCustomer.customer_id) {
      /** Customer Not Selected Alert */
      selectTheCustomerAlert();
      return;
    }

    let customerOldBalance;

    // Check if there's any data for the selected customer
    if (filteredCustomerOrderedData.length === 0) {
      console.error("No data available for the selected customer");
      customerOldBalance = await getOBCustomerDetails(newCustomer.customer_id);
    }

    customerOldBalance = await getOBCustomerDetails(newCustomer.customer_id);

    console.log("customerBalance", customerOldBalance);

    let maaralJSON = customerOldBalance.data.mData[0]?.maaral_json
      ? JSON.parse(customerOldBalance.data.mData[0].maaral_json)
      : [];

    let totalCustomerOBAmount = 0;
    let totalCustomerMaaralAmount = 0;

    console.log("totalCustomerMaaralAmount", totalCustomerMaaralAmount);

    customerOldBalance.data.mData.forEach((item) => {
      totalCustomerOBAmount += parseFloat(item.ob_amount) || 0;
    });

    maaralJSON.forEach((item) => {
      let amount = parseFloat(item.maaral_amount);
      if (!isNaN(amount)) {
        totalCustomerMaaralAmount += amount;
      }
    });

    const reviewedData = filteredCustomerOrderedData.filter(
      (item) => item.is_reviewed == 1
    );

    const filteredData = reviewedData.filter((item) => item.is_delete === 0);

    filteredData.sort((a, b) => {
      const dateA = new Date(processedDate(a.order_date));
      const dateB = new Date(processedDate(b.order_date));
      return dateA - dateB;
    });

    // Generate the receipt content
    const receiptContent = filteredData.map((item) => {
      const orderDate = new Date(item.order_date);

      // Extract day, month, and year components
      const day = orderDate.getDate();
      const month = orderDate.getMonth() + 1; // Month is zero-based, so add 1

      // Ensure day and month are formatted with leading zeros if necessary
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;

      // Extract the last two digits of the year

      // Concatenate components to form the desired date format
      const formattedDate = formattedDay + "-" + formattedMonth;

      return {
        order_id: item.order_id,
        product: item.description,
        weight: item.weight,
        orderDate: formattedDate, // Use the formatted date
      };
    });

    const totalWeight = receiptContent
      .reduce((total, item) => total + parseFloat(item.weight), 0)
      .toFixed(3);

    // Calculate individual weight for each product
    let adjustedTotalWeight = parseFloat(totalWeight);
    const productSummary = {};
    receiptContent.forEach((item) => {
      const productName = item.product.trim();
      if (
        productName === "70" ||
        productName === "80" ||
        productName === "WS"
      ) {
        if (!productSummary[productName]) {
          productSummary[productName] = 0;
        }
        // productSummary[productName] += parseFloat(item.weight);
        const weight = parseFloat(item.weight);
        productSummary[productName] += weight;
        adjustedTotalWeight -= weight;
      }
    });

    const adjustedTotalWeightStr = adjustedTotalWeight.toFixed(3);

    // Create a new window for printing
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
    <html>
      <head>
      <title>Print Receipt</title>
        <style>
          /* Add your custom styles for the receipt here */
          body {
            font-family: Arial, sans-serif;
            font-size : 15px,
            margin-top: 1%
          }
          table {
            border-collapse: collapse;
            width: 70%;
            font-size : 15px
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }
          td:nth-child(1){
            width : 13%,
            display : flex,
            flex-wrap : nowrap,
          }
          td:nth-child(2){
            width : 10%
          }
          td:nth-child(3){
            width : 5%
          }
          button {
            margin-top: 20px;
            padding: 10px 20px;
            background-color: #4caf50;
            color: white;
            border: none;
            cursor: pointer;
          }
          button:hover {
            background-color: #45a049;
          }
          h3,h4,p,h1{
            text-align:center;
            font-size:15px
          }
          .spacing{
            margin : 1px
          }
        </style>
      </head>
      <body>
      <p style="text-align:center, font-size:13px">உ</p>
      <h3 style="margin-top : 3px,font-size:15px">${selectedCustomerName}</h3>
      <div style="display : flex; justify-content : space-between ; margin-top : -20px">
      <h5 style="font-size:10px">Date: ${currentDate()}</h5>
      </div>
        <table>
            <tr>
              <th>Date</th>
               <th>Weight</th>
              <th>Details</th>
            </tr>
          <tbody>
            ${receiptContent
              .map(
                (item) => `
              <tr>
                <td>${item.orderDate}</td>
                 <td>${item.weight}</td>
                <td>${item.product}</td>
              </tr>
            `
              )
              .join("")}
              <tr>
              <td colspan="2" style="text-align: right;">Total Weight:</td>
              <td>${adjustedTotalWeightStr}</td>
            </tr> 
            <tr>
            <td colspan="2" style="text-align: right;">OB:</td>
            <td>${ob}</td>
          </tr>
          ${Object.keys(productSummary)
            .map(
              (productName) => `
            <tr>
              <td colspan="2" style="text-align: right;"><strong>${productName}</strong> Weight:</td>
              <td>${productSummary[productName].toFixed(3)}</td>
            </tr>
          `
            )
            .join("")}
          </tbody>
        </table>
        <br>




        ${
          parseInt(totalCustomerOBAmount) > 0
            ? `<h2>OB</h2>
          <table>
            <tr>
              <th>Date</th>
               <th>Details</th>
               <th>Amount</th>
            </tr>
          <tbody>
            ${customerOldBalance.data.mData
              .map(
                (item) => `
              <tr>
                <td>${item.created_at.split("T")[0] || "-"}</td>
                 <td>${item.ob || "-"}</td>
                <td>${item.ob_amount || "-"}</td>
                
              </tr>
            `
              )
              .join("")}
              <tr>
              <td>Total OB Amount</td>
              <td></td>
              <td>${totalCustomerOBAmount || "-"}</td>
            </tr>
        
          </tbody>
          
        </table>
        <br>`
            : ""
        } 




       ${
         parseInt(totalCustomerMaaralAmount) > 0
           ? ` <h2>Maaral</h2>
          <table>
            <tr>
              <th>Date</th>
               <th>Details</th>
               <th>Amount</th>
            </tr>
          <tbody>
            ${maaralJSON
              .map(
                (item) => `
              <tr>
                 <td>${
                   customerOldBalance.data.mData[0].created_at.split("T")[0]
                 }</td>
                 <td>${item.maaral || "-"}</td>
                  <td>${item.maaral_amount || "-"}</td>
                
              </tr>
            `
              )
              .join("")}
              <tr>
              <td>Total Maaral Amount</td>
              <td></td>
              <td>${totalCustomerMaaralAmount || "-"}</td>
            </tr> 
         
          </tbody>
        </table>`
           : ""
       }
        <h3>Thank You</h3>
        <h3>SKV</h3>
      </body>
    </html>
  `);
    // Call the print function after the document is written
    printWindow.document.close();
    printWindow.print();

    // Call the ownerReceipt function immediately
    ownerReceipt();

    // Close the window if it's not null
    if (printWindow) {
      printWindow.close();
    }
  };

  const ownerReceipt = async () => {
    // Generate the receipt content

    const reviewedData = filteredCustomerOrderedData.filter(
      (item) => item.is_reviewed == 1
    );

    let customerOldBalance = await getOBCustomerDetails(
      newCustomer.customer_id
    );

    let maaralJSON = customerOldBalance.data.mData[0]?.maaral_json
      ? JSON.parse(customerOldBalance.data.mData[0].maaral_json)
      : [];

    let totalCustomerOBAmount = 0;
    let totalCustomerMaaralAmount = 0;
    customerOldBalance.data.mData.map(
      (item) => (totalCustomerOBAmount += parseFloat(item.ob_amount))
    );
    maaralJSON.map(
      (item) => (totalCustomerMaaralAmount += parseFloat(item.maaral_amount))
    );

    const filteredData = reviewedData.filter((item) => item.is_delete === 0);

    filteredData.sort((a, b) => {
      const dateA = new Date(processedDate(a.order_date));
      const dateB = new Date(processedDate(b.order_date));
      return dateA - dateB;
    });

    const receiptContent = filteredData.map((item) => {
      const OD = item.order_date == null ? "0000-00-00" : item.order_date;
      const orderDate = new Date(OD);

      // Extract day, month, and year components
      const day = orderDate.getDate();
      const month = orderDate.getMonth() + 1; // Month is zero-based, so add 1

      // Ensure day and month are formatted with leading zeros if necessary
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;

      // Extract the last two digits of the year

      // Concatenate components to form the desired date format
      const formattedDate = formattedDay + "-" + formattedMonth;

      return {
        order_id: item.order_id,
        product: item.description,
        weight: item.weight,
        orderDate: formattedDate, // Use the formatted date
      };
    });

    const totalWeight = receiptContent
      .reduce((total, item) => total + parseFloat(item.weight), 0)
      .toFixed(3);

    let adjustedTotalWeight = parseFloat(totalWeight);

    const productSummary = {};
    receiptContent.forEach((item) => {
      const productName = item.product.trim();
      if (
        productName === "70" ||
        productName === "80" ||
        productName === "WS"
      ) {
        if (!productSummary[productName]) {
          productSummary[productName] = 0;
        }
        const weight = parseFloat(item.weight);
        productSummary[productName] += weight;
        adjustedTotalWeight -= weight;
      }
    });

    const adjustedTotalWeightStr = adjustedTotalWeight.toFixed(3);

    // Create a new window for printing
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`<html>
    <head>
    <style>
    /* Add your custom styles for the receipt here */
    body {
      font-family: Arial, sans-serif;
      font-size : 15px
    }
    table {
      margin-left : 2px;
      border-collapse: collapse;
      width: 70%;
      font-size : 15px
    }
    th, td {
      border: 1px solid black;
      padding: 5px;
      text-align: left;
    }
    th {
      font-size : 15px;
      background-color: #f2f2f2;
    }
    td:nth-child(1){
      width : 3%
    }
    td:nth-child(2){
      width : 15%,
      display : flex,
      flex-wrap : nowrap,
    }
    td:nth-child(3){
      width : 13%
    }
    td:nth-child(4){
      width : 5%
    }
    button {
      margin-top: 20px;
      padding: 10px 20px;
      background-color: #4caf50;
      color: white;
      border: none;
      cursor: pointer;
    }
    button:hover {
      background-color: #45a049;
    }
    h3,h4,p,h1{
      text-align:center;
    }
    .spacing{
      margin : 1px
    }

      /* OB & Maaral Table Fix */
    .summary-table {
      width: 50%;
      margin-left : 2px;
      margin: 10px auto; /* Centering the tables */
    }
    .summary-table th, .summary-table td {
      text-align: center;
      width: 50%;
    }
  </style>
    </head>
    <body>
    <p style="text-align:center">உ</p>
      <h3 style="margin-top : 3px">${selectedCustomerName}</h3>
      <div style="display : flex; justify-content : space-between ; margin-top : -20px">
      <h5>Date: ${currentDate()}</h5>
      </div>
      <table>
          <tr>
            <th>Order ID</th>
            <th>Date</th>
            <th>Details</th>
            <th>Weight</th>
          </tr>
        <tbody>
          ${receiptContent
            .map(
              (item) => `
            <tr>
              <td>${item.order_id}</td>
              <td style="font-size : 15px">${item.orderDate}</td>
              <td>${item.product}</td>
              <td>${item.weight}</td>
            </tr>
          `
            )
            .join("")}
            <tr>
            <td colspan="3" style="text-align: right;">Total Weight:</td>
            <td>${adjustedTotalWeightStr}</td>
          </tr> 
          <tr>
          <td colspan="3" style="text-align: right;">OB:</td>
          <td>${ob}</td>
        </tr>
        ${Object.keys(productSummary)
          .map(
            (productName) => `
          <tr>
            <td colspan="3" style="text-align: right;"><strong>${productName}</strong> Total Weight:</td>
            <td>${productSummary[productName].toFixed(3)}</td>
          </tr>
        `
          )
          .join("")}
        </tbody>
      </table>

      ${
        parseInt(totalCustomerOBAmount) > 0
          ? `<h2>OB</h2>
          <table class="summary-table">
            <tr>
              <th>Date</th>
               <th>Details</th>
                <th>Amount</th>
            </tr>
          <tbody>
            ${customerOldBalance.data.mData
              .map(
                (item) => `
              <tr>
             
  <td style="padding-right: 20px;">${item.created_at.split("T")[0]}</td>
  <td style="padding-left: 20px;">${item.ob}</td>
    <td style="padding-left: 20px;">${item.ob_amount}</td>
</tr>
                
              
            `
              )
              .join("")}
             
                <tr>
              <td>Total OB Amount</td>
              <td></td>
              <td>${totalCustomerOBAmount}</td>
            </tr>
             
          
        
          </tbody>
        </table>
        <br>`
          : ""
      }




        ${
          parseInt(totalCustomerMaaralAmount)
            ? `<h2>Maaral</h2>
          <table class="summary-table">
            <tr>
              <th>Date</th>
               <th>Details</th>
               <th>Amount</th>
            </tr>
          <tbody>
            ${maaralJSON
              .map(
                (item) => `
              <tr>
                <td>${
                  customerOldBalance.data.mData[0].created_at.split("T")[0]
                }</td>
                 <td>${item.maaral}</td>
                 <td>${item.maaral_amount}</td>

                
              </tr>
            `
              )
              .join("")}
             <tr>
              <td>Total OB Amount</td>
              <td></td>
              <td>${totalCustomerMaaralAmount}</td>
            </tr>
         
          </tbody>
        </table>`
            : ""
        }
      <h3>Thank You</h3>
      <h3>SKV</h3>
      <p>Own Copy</p>
    </body>
  </html>
    `);

    // Call the print function after the document is written
    printWindow.document.close();
    printWindow.print();
  };

  const columns = [
    {
      field: "order_id",
      headerName: "Order ID",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "contact_person_name",
      headerName: "Name",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "purity",
      headerName: "Group",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "order_status",
      headerName: "Order Status",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        switch (params.value) {
          case 0:
            return "Pending";
          case 1:
            return "InProgress";
          case 2:
            return "Completed";
          default:
            return null;
        }
      },
    },
    {
      field: "is_reviewed",
      headerName: "Is Reviewed",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        return params.value === 0 ? "Pending" : "Approved";
      },
    },
    {
      field: "order_date",
      headerName: "Ordered Date",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        return processedDate(params.value);
      },
    },
  ];
  const processedDate = (date) => {
    if (date == null) {
      return "0000-00-00";
    }
    const formattedDate = date.split("T")[0];
    return formattedDate;
  };

  const generateSummaryReport = () => {
    generateSummary(filteredCustomerOrderedData);
  };

  return (
    <Box sx={{ height: 500, width: "97%", marginX: "2%" }}>
      {/* Responsive Layout using Grid */}
      <Grid
        container
        spacing={2}
        alignItems="center"
        // justifyContent="space-between"
        // sx={{ mb: 2 }}
      >
        {/* Customer Autocomplete */}
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <Autocomplete
              options={customerOptions}
              getOptionLabel={(option) => option.contact_person_name}
              value={
                customerOptions.find(
                  (option) => option.customer_id === newCustomer.customer_id
                ) || null
              }
              onChange={(event, newValue) => {
                handleInputChange({
                  target: {
                    name: "customer_id",
                    value: newValue ? newValue.customer_id : "",
                  },
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Customer" required />
              )}
              isOptionEqualToValue={(option, value) =>
                option.customer_id === value.customer_id
              }
            />
          </FormControl>
        </Grid>

        {/* Order Status Select */}
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Order Status</InputLabel>
            <Select
              value={orderStatusFilter}
              onChange={handleOrderStatusFilterChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="0">Pending</MenuItem>
              <MenuItem value="2">Closed</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Group Select */}
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Group</InputLabel>
            <Select value={purityFilter} onChange={handlePurityFilterChange}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="SKV/5">SKV/5</MenuItem>
              <MenuItem value="SKV?80">SKV?80</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* From Date Picker */}
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            fullWidth
            id="from-date"
            label="From Date"
            type="date"
            value={fromDate}
            onChange={handleFromDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* To Date Picker */}
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            fullWidth
            id="to-date"
            label="To Date"
            type="date"
            value={toDate}
            onChange={handleToDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* Buttons for Print Receipt and Summary */}
        <Grid item xs={12} sm={6} md={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={generateReceipt}
          >
            Print Receipt
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={generateSummaryReport}
          >
            Summary
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={generateOverallCustomerOB}
          >
            Generate Over All OBs
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        rows={filteredCustomerOrderedData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
      />
    </Box>
  );
}
