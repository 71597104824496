// import axios from "axios";
// import { baseURL } from "../support/baseURL";
// import { currentDate } from "../support/receiptGeneration";

// export const generateOverallCustomerOB = async () => {
//   try {
//     const getOBCustomerDetails = async () => {
//       try {
//         return await axios.post(`${baseURL}/ob/getOB`, {
//           customer_id: "All",
//         });
//       } catch (error) {
//         console.error(error.message);
//       }
//     };

//     const customerOldBalance = await getOBCustomerDetails();

//     if (!customerOldBalance?.data?.mData?.length) {
//       console.log("No OB data available");
//       return;
//     }

//     // Group data by customer_id
//     const customerGroups = {};
//     customerOldBalance.data.mData.forEach((item) => {
//       if (!customerGroups[item.customer_id]) {
//         customerGroups[item.customer_id] = {
//           name: item.contact_person_name,
//           entries: [],
//           maaralJSON: item.maaral_json ? JSON.parse(item.maaral_json) : [],
//         };
//       }
//       customerGroups[item.customer_id].entries.push(item);
//     });

//     let reportContent = "";

//     // Generate report for each customer
//     Object.entries(customerGroups).forEach(([customerId, customerData]) => {
//       const { name, entries, maaralJSON } = customerData;

//       let totalCustomerOBAmount = 0;
//       let totalCustomerMaaralAmount = 0;

//       const validOBEntries = entries.filter(
//         (item) =>
//           item.ob &&
//           item.ob_amount &&
//           item.ob.trim() !== "" &&
//           item.ob_amount.trim() !== ""
//       );
//       validOBEntries.forEach(
//         (item) => (totalCustomerOBAmount += parseFloat(item.ob_amount) || 0)
//       );

//       console.log("maaralJSON", maaralJSON);

//       const validMaaralEntries = maaralJSON.filter(
//         (item) =>
//           item.maaral &&
//           item.maaral_amount &&
//           item.maaral.trim() != "" &&
//           item.maaral_amount.trim() != ""
//       );

//       console.log(
//         "validMaaralEntries",
//         validMaaralEntries,
//         validMaaralEntries.length
//       );
//       validMaaralEntries.forEach(
//         (item) =>
//           (totalCustomerMaaralAmount += parseFloat(item.maaral_amount) || 0)
//       );

//       reportContent += `
//         <h4>${name}</h4>

//         ${
//           validOBEntries.length > 0
//             ? `
//           <h5>Opening Balance Details</h5>
//           <table>
//             <tr>
//               <th>Date</th>
//               <th>Details</th>
//               <th>Amount</th>
//             </tr>
//             ${validOBEntries
//               .map(
//                 (item) => `
//               <tr>
//                 <td>${item.created_at.split("T")[0] || "-"}</td>
//                 <td>${item.ob || "-"}</td>
//                 <td>${item.ob_amount || "-"}</td>
//               </tr>
//             `
//               )
//               .join("")}
//             <tr class="total-row">
//               <td colspan="2">Total OB Amount</td>
//               <td>${totalCustomerOBAmount.toFixed(2)}</td>
//             </tr>
//           </table>
//         `
//             : ""
//         }

//         ${
//           validMaaralEntries.length > 0
//             ? `
//           <h5>Maaral Details</h5>
//           <table>
//             <tr>
//               <th>Date</th>
//               <th>Details</th>
//               <th>Amount</th>
//             </tr>
//             ${validMaaralEntries
//               .map(
//                 (item) => `
//               <tr>
//                 <td>${entries[0].created_at.split("T")[0]}</td>
//                 <td>${item.maaral || "-"}</td>
//                 <td>${item.maaral_amount || "-"}</td>
//               </tr>
//             `
//               )
//               .join("")}
//             <tr class="total-row">
//               <td colspan="2">Total Maaral Amount</td>
//               <td>${totalCustomerMaaralAmount.toFixed(2)}</td>
//             </tr>
//           </table>
//         `
//             : ""
//         }

//         <div class="customer-end"></div>
//       `;
//     });

//     const printWindow = window.open("", "_blank");
//     printWindow.document.write(`
//       <html>
//         <head>
//           <title>Overall Customer OB Report</title>
//           <style>
//             body {
//               font-family: Arial, sans-serif;
//               font-size: 15px;
//               margin: 10px;
//             }
//             table {
//               border-collapse: collapse;
//               width: 70%;
//               margin: 10px auto;
//             }
//             th, td {
//               border: 1px solid black;
//               padding: 8px;
//               text-align: left;
//             }
//             th {
//               background-color: #f2f2f2;
//             }
//             h3, h4, h5 {
//               text-align: center;
//             }
//             .total-row {
//               font-weight: bold;
//             }
//             .customer-end {
//               text-align: center;
//               margin: 20px 0;
//               border-top: 2px dashed #000;
//               padding-top: 5px;
//               font-weight: bold;
//               color: #666;
//             }
//           </style>
//         </head>
//         <body>
//           <h3>Overall Customer OB Report</h3>
//           <p>Date: ${currentDate()}</p>
//           ${reportContent}
//           <h3>SKV</h3>
//         </body>
//       </html>
//     `);

//     printWindow.document.close();
//     printWindow.print();
//     printWindow.close();
//   } catch (error) {
//     console.error("Error generating overall customer OB:", error);
//   }
// };

import axios from "axios";
import { baseURL } from "../support/baseURL";
import { currentDate } from "../support/receiptGeneration";

export const generateOverallCustomerOB = async () => {
  try {
    const getOBCustomerDetails = async () => {
      try {
        return await axios.post(`${baseURL}/ob/getOB`, {
          customer_id: "All",
        });
      } catch (error) {
        console.error(error.message);
      }
    };

    const customerOldBalance = await getOBCustomerDetails();

    if (!customerOldBalance?.data?.mData?.length) {
      console.log("No OB data available");
      return;
    }

    // Group data by contact_person_name
    const customerGroups = {};
    customerOldBalance.data.mData.forEach((item) => {
      const name = item.contact_person_name;
      if (!customerGroups[name]) {
        customerGroups[name] = {
          obEntries: [],
          maaralEntries: [],
        };
      }
      // Add OB entry if valid
      if (
        item.ob &&
        item.ob_amount &&
        item.ob.trim() !== "" &&
        item.ob_amount.trim() !== ""
      ) {
        customerGroups[name].obEntries.push(item);
      }
      // Parse and add Maaral entries if they exist
      if (item.maaral_json) {
        const maaralJSON = JSON.parse(item.maaral_json);
        maaralJSON.forEach((maaralItem) => {
          if (
            maaralItem.maaral &&
            maaralItem.maaral_amount &&
            maaralItem.maaral.trim() !== "" &&
            maaralItem.maaral_amount.trim() !== ""
          ) {
            customerGroups[name].maaralEntries.push({
              ...maaralItem,
              date: item.created_at, // Attach the parent entry's date
            });
          }
        });
      }
    });

    let reportContent = "";

    // Generate report for each customer name
    Object.entries(customerGroups).forEach(([name, data]) => {
      const { obEntries, maaralEntries } = data;

      let totalCustomerOBAmount = 0;
      obEntries.forEach(
        (item) => (totalCustomerOBAmount += parseFloat(item.ob_amount) || 0)
      );

      let totalCustomerMaaralAmount = 0;
      maaralEntries.forEach(
        (item) =>
          (totalCustomerMaaralAmount += parseFloat(item.maaral_amount) || 0)
      );

      reportContent += `
        <h4>${name}</h4>

        ${
          obEntries.length > 0
            ? `
          <h5>Opening Balance Details</h5>
          <table>
            <tr>
              <th>Date</th>
              <th>Details</th>
              <th>Amount</th>
            </tr>
            ${obEntries
              .map(
                (item) => `
              <tr>
                <td>${item.created_at.split("T")[0] || "-"}</td>
                <td>${item.ob || "-"}</td>
                <td>${item.ob_amount || "-"}</td>
              </tr>
            `
              )
              .join("")}
            <tr class="total-row">
              <td colspan="2">Total OB Amount</td>
              <td>${totalCustomerOBAmount.toFixed(2)}</td>
            </tr>
          </table>
        `
            : ""
        }

        ${
          maaralEntries.length > 0
            ? `
          <h5>Maaral Details</h5>
          <table>
            <tr>
              <th>Date</th>
              <th>Details</th>
              <th>Amount</th>
            </tr>
            ${maaralEntries
              .map(
                (item) => `
              <tr>
                <td>${item.date.split("T")[0]}</td>
                <td>${item.maaral || "-"}</td>
                <td>${item.maaral_amount || "-"}</td>
              </tr>
            `
              )
              .join("")}
            <tr class="total-row">
              <td colspan="2">Total Maaral Amount</td>
              <td>${totalCustomerMaaralAmount.toFixed(2)}</td>
            </tr>
          </table>
        `
            : ""
        }

        <div class="customer-end">=== END OF CUSTOMER: ${name} ===</div>
      `;
    });

    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Overall Customer OB Report</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              font-size: 15px;
              margin: 20px;
            }
            table {
              border-collapse: collapse;
              width: 70%;
              margin: 20px auto;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            h3, h4, h5 {
              text-align: center;
            }
            .total-row {
              font-weight: bold;
            }
            .customer-end {
              text-align: center;
              margin: 20px 0;
              border-top: 2px dashed #000;
              padding-top: 10px;
              font-weight: bold;
              color: #666;
            }
          </style>
        </head>
        <body>
          <h3>Overall Customer OB Report</h3>
          <p>Date: ${currentDate()}</p>
          ${reportContent}
          <h3>SKV</h3>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  } catch (error) {
    console.error("Error generating overall customer OB:", error);
  }
};
